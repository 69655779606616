<template>
  <div>
    <!-- The header buttons -->
    <div class="d-flex justify-space-between">
      <!-- Show the go back button -->
      <v-btn
        text
        color="primary"
        :disabled="isMakingRequest"
        @click="$router.push({ name: 'InfluencerOutreachDetail', params: { uuid: $route.params.uuid } })"
      >
        <v-icon left>
          arrow_back
        </v-icon>

        Back
      </v-btn>
    </div>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachAnalyticsAll"
}
</script>
